var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "habitats-admin-common-WebsitePageSelector" },
    [
      _c("treeselect", {
        attrs: {
          value: _vm.internalValue,
          options: _vm.treeOptions,
          clearAllText: "Verwijder allemaal",
          clearValueText: "Verwijder",
          loadingText: "Laden..",
          noChildrenText: "Geen pagina's",
          noOptionsText: "Geen keuzes..",
          noResultsText: "Geen keuzes..",
          retryText: "Probeer opnieuw",
          searchPromptText: "Typ om te zoeken",
        },
        on: {
          input: function ($event) {
            return _vm.onInput($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "option-label",
            fn: function ({ node }) {
              return [
                node.raw.type == "menu"
                  ? _c(
                      "div",
                      {
                        staticClass: "text-sm",
                        class: {
                          "font-bold":
                            _vm.selectedParents.indexOf(
                              "websiteMenu-" + node.raw.websiteMenu.id
                            ) !== -1,
                          "py-1": true,
                        },
                      },
                      [_vm._v(_vm._s(node.raw.name) + " menu")]
                    )
                  : _c(
                      "div",
                      {
                        class: {
                          "bg-none": true,
                          "text-sm": true,
                          "text-blue-400":
                            node.raw.websiteMenuPage.website_page.id ==
                            _vm.value,
                          "font-bold":
                            node.raw.websiteMenuPage.website_page.id ==
                              _vm.value ||
                            _vm.selectedParents.indexOf(
                              "websitePage-" +
                                node.raw.websiteMenuPage.website_page.id
                            ) !== -1,
                        },
                      },
                      [_vm._v(_vm._s(node.raw.title))]
                    ),
              ]
            },
          },
          {
            key: "value-label",
            fn: function ({ node }) {
              return _c("div", {}, [_vm._v(_vm._s(node.raw.title))])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }