var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "common-form-inputs-Image",
      class: { dragHover: _vm.dragHover },
      on: {
        drop: _vm.onDrop,
        dragover: _vm.onDragOver,
        dragleave: _vm.onDragLeave,
      },
    },
    [
      _vm.imageUrl && !_vm.uploadCancellationToken
        ? _c("img", {
            staticClass: "mb-3 shadow-md",
            attrs: { src: _vm.imageUrl },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.imageUrl ? _c("br") : _vm._e(),
      _vm._v(" "),
      _c(
        "label",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.imageUrl || _vm.uploadCancellationToken,
              expression: "!imageUrl || uploadCancellationToken",
            },
          ],
          ref: "dropzone",
          staticClass:
            "border-dashed border-gray-400 h-32 bg-gray-100 text-gray-500 hover:text-gray-600 text-center text-sm flex cursor-pointer p-0 dropzone",
          on: {
            click: function ($event) {
              _vm.uploadCancellationToken ? _vm.cancelUpload($event) : null
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "m-auto px-2" },
            [
              _vm.uploadCancellationToken && _vm.uploadPercentage < 100
                ? [
                    _c("div", { staticClass: "rounded bg-gray-200 mb-2" }, [
                      _c("div", {
                        staticClass: "rounded bg-green-500 h-2",
                        style: { width: _vm.uploadPercentage + "%" },
                      }),
                    ]),
                    _vm._v("\n\n                Bezig met uploaden..."),
                    _c("br"),
                    _vm._v(" "),
                    _c("span", { staticClass: "text-xs text-gray-400" }, [
                      _vm._v("Klik om te annuleren."),
                    ]),
                  ]
                : _vm.uploadCancellationToken && _vm.uploadPercentage == 100
                ? [
                    _c("i", {
                      staticClass: "fa fa-circle-notch text-2xl fa-spin",
                    }),
                    _vm._v(" "),
                    _vm._m(0),
                  ]
                : [
                    _c("i", { staticClass: "fa fa-upload text-2xl" }),
                    _vm._v(" "),
                    _vm._m(1),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c("input", {
            staticClass: "hidden",
            attrs: { type: "file" },
            on: { change: _vm.onChange },
          }),
        ]
      ),
      _vm._v(" "),
      !_vm.uploadCancellationToken && _vm.imageUrl
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-outline-danger btn-sm rounded-full px-4 py-2 mt-1 mb-2",
              attrs: { type: "button" },
              on: { click: _vm.removeImage },
            },
            [
              _c("i", { staticClass: "fa fa-trash mr-1" }),
              _vm._v(" Verwijder\n    "),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.dropzoneRefPresent && _vm.hasCrop
        ? _c("avatar-cropper", {
            ref: "avatarCropper",
            attrs: {
              uploadHandler: _vm.cropUploadHandler,
              labels: {
                submit: "Gereed",
                cancel: "Annuleer",
              },
              trigger: _vm.$refs["dropzone"],
              outputOptions: _vm.outputOptions,
              cropperOptions: _vm.cropperOptions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        ref: "input",
        attrs: { type: "hidden", name: _vm.inputName },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-2" }, [
      _vm._v("\n                    Bezig met optimaliseren.."),
      _c("br"),
      _vm._v("\n                    Klik om te annuleren.\n                "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mt-2" }, [
      _vm._v(
        "\n                    Drag 'n drop hier een afbeelding\n                    "
      ),
      _c("br"),
      _vm._v("of klik hier.\n                "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }