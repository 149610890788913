var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("WebsitePageSelector", {
        ref: "websitePageSelector",
        attrs: { value: _vm.value, language: "nl" },
        on: {
          "update:value": [
            function ($event) {
              _vm.value = $event
            },
            _vm.onValueUpdate,
          ],
        },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        ref: "hiddenInput",
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.value },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.value = $event.target.value
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }